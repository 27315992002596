import { navigate } from 'gatsby';
import { logItem } from '@slices/loggingSlice';
import { useLayoutEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { IS_IN_QA_MODE, WINDOW_HASH } from '../../sharedConstants';

const useNavigateLog = ({ questionName = '' }: { questionName: string }) => {
  const isNotInDevMode =
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test' &&
    !IS_IN_QA_MODE;

  const { hasStarted } = useAppSelector(e => e?.gameState);
  const dispatch = useAppDispatch();

  // if user restored page use bfCache, we have to kick them back always
  const handleBfCache = useCallback(
    (event: PageTransitionEvent) => {
      if (event.persisted && isNotInDevMode) {
        navigate(`/${WINDOW_HASH}`, {
          state: {
            userReset: true,
          },
        });
      }
    },
    [isNotInDevMode],
  );

  useLayoutEffect(() => {
    // run this on all pages, other than the first
    if (location?.pathname !== '/') {
      window.addEventListener('pageshow', handleBfCache);
      if (window?.session_id && hasStarted) {
        // log which url the user is at
        dispatch(
          logItem({
            question_name: questionName,
            collection_name: 'events',
            event_type: 'navigate',
            location: window?.location?.pathname,
            target: 'nav to',
          }),
        );
      } else if (isNotInDevMode) {
        // kick user if no session hash present, and we are not in dev mode
        navigate(`/${WINDOW_HASH}`, {
          state: {
            userReset: true,
          },
        });
      }
    }
    return () => {
      window.removeEventListener('pageshow', handleBfCache);
    };
    // can only run on mount, so I don't care about where
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};

export default useNavigateLog;
